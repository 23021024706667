.wrapper {
    position: relative;
    background: white;
    width: 100%;
    height: 50vh;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
.noResult {
    text-align: center;
    border-radius: 20px;
}
.listWrapper {
    width: 55%;
    background: white;
    position: relative;
}
.list {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    color: black;
}
.seeResults {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
}
.seeResults button {
    margin: 0;
}
.result svg {
    margin-right: 10px;
}
.result p {
    line-height: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.implantLink {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
}
.icon {
    height: 30px;
    min-width: 30px;
}
.approved svg {
    color: rgb(112, 197, 73);
}
.result a {
    color: black;
}
.result:last-child .implantLink {
    border-bottom: none;
}
.hover {
    background: rgb(220, 220, 220);
}
.preview {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    width: 45%;
    border-left: 1px solid #e0e0e0;
}
.seeResults button:hover {
    background: white;
    color: black;
}
@media (max-width: 768px) {
    .listWrapper {
        width: 100%;
    }
    .wrapper {
        height: 60vh;
    }
}
