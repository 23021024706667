@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;
        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;
        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;
        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;
        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;
        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;
        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;
        --ring: 215 20.2% 65.1%;
        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;
        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;
        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;
        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;
        --border: 216 34% 17%;
        --input: 216 34% 17%;
        --card: 224 71% 4%;
        --card-foreground: 213 31% 91%;
        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;
        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;
        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;
        --ring: 216 34% 17%;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply font-sans antialiased bg-background text-foreground;
    }
}
:root {
    --dark-grey: rgb(0, 0, 0);
    --main-grey: rgb(10, 10, 10);
    --light-grey: rgb(17, 17, 17);
    --bright-grey: rgb(34, 34, 34);
    --nav-background: rgb(37, 37, 37);
    --extra-bright-grey: rgba(128, 128, 128, 0.25);
    --transparent-bright-grey: rgba(128, 128, 128, 0.1);
    --main-grey-with-opacity: rgba(128, 128, 128, 0.25);
    --grey: rgb(128, 128, 128);
    --white: rgb(255, 255, 255);
    --main-white: rgb(238, 238, 238);
    --line-color: #ddd; /* Light gray for separating lines */
    --cell-bg: #545151; /* Light background color for table cells */
    --header-bg: #333; /* Dark background color for table header */
    --main-white: #fff; /* White color for text and borders, if needed */
    --main-color: #4caf50; /* Primary color, for buttons or highlights */

    --main-red: rgb(255, 129, 129);
    --main-blue: rgb(78, 165, 217);
    --main-purple: rgb(68, 69, 137);
    --main-selectbox: rgb(79, 79, 79);
    --main-uploadText: rgb(238, 238, 238);
    --main-secondaryBottomBackground: rgb(0, 0, 0);
    --main-uploadBackground: rgb(0, 0, 0);
    --main-uploadborder: rgb(78, 165, 217);
    --main-selectBorder: transparent;
    --main-hideEye: rgb(85, 85, 85);

    /* Common variables */
    --top-margin: 36px;
    --bottom-margin: 50px;

    /* Variables for navigation bar measure */
    --nav-icon-size: 20px;
    --nav-spacing: 13px;
    --nav-text-size: 1rem;
    --nav-item-padding: 8px;
    --nav-item-gap: 8px;
    --form-item-gap: 13px;
    --nav-width: 250px;
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;
        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;
        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;
        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;
        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;
        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;
        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;
        --ring: 215 20.2% 65.1%;
        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;
        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;
        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;
        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;
        --border: 216 34% 17%;
        --input: 216 34% 17%;
        --card: 224 71% 4%;
        --card-foreground: 213 31% 91%;
        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;
        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;
        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;
        --ring: 216 34% 17%;
    }
}

[data-theme='light'] {
    /* sidebar and dashboard bg color and text color*/
    --bright-grey: rgb(246, 246, 244); /* Color Segmentation */
    --main-grey: rgb(246, 246, 244); /* sidebar */
    --light-grey: rgb(255, 255, 255); /* main area */
    --main-white: rgb(128, 128, 128); /* text */
    --main-purple: rgb(204, 204, 204); /* toggle button */
    --dark-grey: rgb(78, 165, 217); /* Button upload background */
    --main-selectbox: rgb(229, 229, 229);
    --main-uploadText: rgb(78, 165, 217);
    --main-secondaryBottomBackground: rgb(246, 246, 244);
    --main-uploadborder: rgb(128, 128, 128);
    --main-uploadBackground: rgb(255, 255, 255);
    --main-selectBorder: rgb(128, 128, 128);
    --main-hideEye: rgb(226, 226, 224);
}
/* Dark mode is defalut one, maybe there is no need to set below */
[data-theme='dark'] {
    --primary-color: var(--main-grey);
}
* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
body {
    margin: 0;
    font-family: 'Inter';
    /* color: var(--main-white); */
}
input {
    font-family: 'Inter';
    /* color: rgb(0, 0, 0); */
}
h1 {
    font-size: 2.5rem;
    font-weight: 300;
}
h2 {
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
}
h3 {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0;
}
h4 {
    font-size: 1.3rem;
    font-weight: 200;
    margin: 0;
}
h5 {
    font-size: 1.1rem;
    font-weight: 400;
    margin: 25px 0 5px 0;
}
p {
    font-weight: 200;
}
a {
    text-decoration: none;
    color: var(--main-white);
}
p a {
    color: var(--main-blue);
}
input,
textarea {
    margin: 4px 0;
    padding: 15px;
    border-radius: 5px;
    /* border: none; */
    -webkit-appearance: none;
}
textarea {
    display: block;
    font-family: 'Montserrat';
    height: 150px;
    border: 1px solid grey;
}
select {
    font-family: 'Montserrat';
    padding: 14px;
    border: 1px solid var(--main-selectBorder);
}
input:focus,
select:focus,
textarea:focus {
    outline: none;
}
form {
    display: flex;
    flex-direction: column;
    gap: var(--form-item-gap);
}
small {
    color: #aaa;
}
ul {
    margin: 0;
    padding-left: 15px;
}
li {
    font-weight: normal;
}
em {
    font-style: normal;
}
