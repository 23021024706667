.info {
    max-width: 300px;
    text-align: center;
    flex-grow: 1;
}
.info h3,
.info p {
    margin: 15px 0;
}
.login-form {
    position: relative;
    max-width: 250px;
    flex-grow: 4;
}
.login-form fieldset {
    padding: 0;
    border: none;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 20px;
}
.login-form input,
.button {
    width: 100%;
}
.login-form label a {
    text-decoration: underline;
}
.login-form .forgotPassword {
    font-size: 0.8rem;
    font-weight: 200;
}
.alert {
    position: absolute;
    color: var(--main-red);
    transform: translateY(-60px);
    max-width: 100%;
    padding: 5px;
}
.alert p {
    font-size: 0.8125rem;
}
/* Forgot password modal */
.forgot-password {
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
}
.linksWrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    gap: 10px;
    flex-grow: 2;
}
.links {
    align-items: flex-start;
    display: flex;
    flex-flow: column nowrap;
}
.highlightedText a {
    color: var(--main-blue);
    /* font-size: 1.1rem; */
    font-weight: 500;
}
@media (max-width: 768px) {
    .info {
        max-width: 100%;
    }
    .login-form {
        max-width: 100%;
    }
    .login-form label {
        font-size: 0.75rem;
        font-weight: 200;
    }
    .alert {
        transform: translateY(0);
    }
}
