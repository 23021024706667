.wrapper {
    width: 50%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header h3 {
    margin-bottom: 20px;
}
.groups {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    margin-bottom: 50px;
}
@media (max-width: 1440px) {
    .wrapper {
        width: 70%;
    }
}
@media (max-width: 1024px) {
    .wrapper {
        width: 85%;
    }
}
@media (max-width: 768px) {
    .wrapper {
        width: 100%;
        padding: 0 20px;
    }
}
@media (max-width: 576px) {
}
