.info {
    /* max-width: 400px; */
    text-align: center;
    /* flex-grow: 1; */
}
.select {
    color: var(--main-grey);
    border-radius: 5px;
}
.info h3,
.info p {
    margin: 0 0 30px 0;
}
.button {
    margin: 50px 0 10px 0;
}
.form {
    /* flex-grow: 4; */
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    /* width: 70%; */
    gap: 20px;
}
/* .form > div {
    width: 100%;
} */
.form input,
.form select,
.button {
    width: 100%;
}
.form label {
    text-align: left;
}
.form label a {
    text-decoration: underline;
}
.checkbox {
    /* width: 80%; */
    display: flex;
    align-items: center;
    /* gap: 10px; */
    justify-content: flex-start;
}
.form-alert {
    background-color: rgba(244, 180, 180, 0.7);
    border-radius: 2px;
    height: 40px;
    line-height: 40px;
    width: 223px;
    margin: auto;
    color: rgba(59, 20, 20, 0.7);
}
.form-alert p {
    font-size: 0.8125rem;
}

.error {
    color: rgb(255, 100, 100);
    font-size: 0.75rem;
}

@media screen and (max-width: 968px) {
    .info h1 {
        font-size: 2rem;
    }
    .info p {
        font-size: 0.9rem;
    }
    .info h3,
    .info p {
        margin: 0;
    }
}
