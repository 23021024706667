.wrapper {
    margin: 50px auto;
    width: 80%;
}
.titleWrapper {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding-top: 15px;
    padding: 15px 10px 0px 10px;
}
/* .roomItem {
    margin-bottom: 1.5em;
    margin: 0 auto;
} */
.rowWrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid var(--main-white);
    padding-bottom: 5px;
    padding-top: 10px;
    font-weight: 200;
}
.rowWrapper > div {
    width: 50%;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}
.addButton {
}

@media (max-width: 968px) {
    .titleWrapper {
        justify-content: space-between;
        margin: 20px 0;
    }
    .wrapper {
        margin: 0;
        width: 100%;
        padding: 10px;
    }
}
