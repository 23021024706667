.header {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.header > h2 {
    margin-top: 60px;
    margin-bottom: 10px;
}
.header button {
    align-self: flex-start;
}
.emptyState {
    padding: 40px;
    text-align: center;
    color: #666;
    border-radius: 4px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.formGroup label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
}

.formGroup input,
.formGroup select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}
