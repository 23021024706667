.grid {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 9fr 3fr;
    gap: 20px;
    padding: 50px;
}
.protocol {
    grid-column: 2 / 3;
    display: flex;
    flex-flow: column;
    gap: 50px;
}
.logContainer {
    grid-column: 3 / 4;
    word-break: break-word;
}
.linkedImplantsContainer {
    display: flex;
    flex-flow: column;
    gap: 10px;
    padding: 20px 0;
}
