.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}
.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
}
.info h3 {
    margin: 25px 0 15px 0;
}
.form {
    margin: 20px 0;
    width: 80%;
    /* flex-grow: 4; */
}
.form input {
    width: 100%;
}
