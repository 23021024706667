.wrapper {
    margin: 5px 0;
    display: flex;
    flex-flow: column wrap;
}
.item > * {
    display: inline;
}
.item {
    display: inline-flex;
    align-items: center;
}
.errorMessage {
    color: var(--main-red);
    font-size: 12px;
}
