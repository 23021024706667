/* NEW TABLE */

.table {
    width: 100%;
    border-collapse: separate; /* Ensure borders are separate */
    border-spacing: 0; /* Prevents spacing between cells */
    border: 1px solid #535353;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 10px;
}

.table th,
.table td {
    padding: 20px;
    vertical-align: top;
    border: 1px solid #535353;
}

.table tr:first-child th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.table tr:first-child th {
    font-size: 14px;
    font-weight: 800;
    color: white;
}
.table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}
caption {
    caption-side: top;
    text-align: left;
    font-weight: bold;
    background-color: #535353;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 0px;
}
.tableHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.tableHeadingButtonsWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
}
.tableHeadingWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
}
.editBtn {
    margin: 0;
}
/* @media (max-width: 768px) {
    .tableHeading {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
} */
