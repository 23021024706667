.wrapper {
    position: relative;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    width: 50vw;
}
.suggestions {
    position: absolute;
    width: 100%;
    top: calc(100% + 10px);
}
.input {
    width: 75%;
}

@media (max-width: 768px) {
    .wrapper {
        width: 90vw;
    }
    .input {
        width: 100%;
    }
}
