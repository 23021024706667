.wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 100px;
    gap: 30px;
}
.cptReportButton {
    position: absolute;
    top: 10px;
    right: 10px;
}
.wrapper h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
}
.search {
    display: flex;
    justify-content: center;
}
.trialBtn {
    font-size: 1.6rem;
}
.trialBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 40px;
}
.actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.actions button,
.historyButton button {
    margin: 0;
}
.actionsTitle {
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .wrapper {
        padding-top: 50px;
    }
    .search {
        width: 100%;
    }
    .actions {
        flex-direction: column;
        align-items: center;
    }
}
@media (max-width: 576px) {
    .wrapper h2 {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}
