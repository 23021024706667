.wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    padding-top: 0px;
    max-width: 400px;
}
.search {
    position: relative;
    text-align: center;
    margin: 0px;
    width: 100%;
}
/* .search input {
    color: var(--main-grey);
    font-size: 1.1rem;
    border-radius: 30px;
    margin: 0;
    padding: 10px 50px 10px 50px;
    width: 600px;
} */
.input {
    display: flex;
    width: 100%;
}
@media (max-width: 579px) {
    /* .search input {
        width: 100%;
    } */
    .input {
        margin-bottom: 20px;
    }
    .search {
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
    }
}

.search input:focus {
    border: 1px solid #4ea5d9;
}

.search .icon {
    filter: invert(0.4);
    width: 20px;
    position: absolute;
    top: 12px;
    left: 20px;
}
.search ul {
    position: absolute;
    left: 0;
    transform: translateX(0);
    z-index: 1;
    background: white;
    color: black;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    max-height: 300px;
    overflow-y: auto;
}
.search ul li a {
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: black;
}
.search ul li {
    width: 100%;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    text-align: left;
    align-items: center;
}
.search ul li:hover {
    cursor: pointer;
    text-decoration: underline;
}
.search ul li svg {
    height: 10px;
    transform: translateY(-1px);
    margin-right: 5px;
}
.highlighter {
    font-weight: normal;
}
.semantic {
    display: flex;
    align-items: center;
}
.label {
    margin-right: 10px;
}
