.inputsWrapper h3 {
    margin-top: 0;
}
.inputsWrapper input {
    width: 100%;
}
.inputsWrapper {
    font-size: 0.85rem;
    text-align: left;
}

.label {
    display: block;
    margin-bottom: 5px;
}
.save button {
    margin: 15px 0;
}
