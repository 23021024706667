.container {
    margin: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    transform: translateX(-15px);
    padding: 10px;
}
.border {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 5px;
    height: 100vh;
}
.selectContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.select {
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: white;
    font-size: 0.9rem;
}

.error {
    color: red;
    font-size: 0.9rem;
}
