.container {
    padding: 40px;
}
.tableContainer {
    margin-bottom: 40px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.tableContainer h3 {
    margin-bottom: 20px;
}
.approve {
    margin: 0;
}

.tableContainer button {
    margin: 0;
}
