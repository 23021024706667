.wrapper {
    margin: 50px auto;
    width: 90%;
}
.wrapper h1 {
    margin-bottom: 30px;
}

@media (max-width: 1400px) {
    .wrapper {
        width: 90%;
    }
}
