.protocolTitle {
    cursor: pointer;
    color: white;
    font-weight: 600;
}
.tableContainer {
    margin-bottom: 40px;
}

.tableHeader {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.tableContainer h3 {
    display: inline;
}

.tableContainer button {
    margin: 0;
}
