.wrapper {
    padding: 15px 20px 20px 20px;
    max-height: 450px;
    /* border: 2px solid white; */
    border-radius: 15px;
}
.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.list {
    border-radius: 10px;
    max-height: 340px;
    border: 2px solid white;
    overflow: auto;
}
