.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-flow: row wrap;
}

.manageSubscriptionButton {
    display: flex;
    align-items: center;
    margin: 0;
}

.infoContainer {
    display: flex;
    gap: 20px;
    flex-flow: row wrap;
}
