.container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.doc {
    transform-origin: top;
    transform: scale(0.9);
    background: #fff;
    color: black;
    padding: 30px;
    overflow-y: auto;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    aspect-ratio: 1 / 1.414;
}
.approval {
    grid-column: 1 / 4;
}
.buttons {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.buttons button {
    margin: 0;
}
.doc h2 {
    font-weight: 700;
}
.doc h3 {
    font-weight: 700;
}
.doc h4 {
    font-weight: 600;
}
.doc p {
    font-weight: 300;
}
.doc p strong {
    font-weight: 600;
}
.doc br {
    margin: 0;
}
.doc li {
    list-style: disc;
}
