.actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.select {
    flex-grow: 2;
}
.action {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.save {
    display: flex;
    justify-content: flex-end;
}
