.container {
    border: 1px solid white;
    border-radius: 10px;
    /* height: 400px;
    width: 400px; */
    display: flex;
    flex-flow: column;
    gap: 20px;
    overflow: hidden;
}
.toggle {
    display: flex;
    justify-content: space-around;
    border: 1px solid white;
    border-radius: 20px;
    padding: 5px;
    gap: 5px;
}
.button {
    cursor: pointer;
    flex-grow: 1;
    text-align: center;
    border-radius: 20px;
    padding: 3px 8px;
}
.button.selected {
    border: 1px solid white;
    background: white;
    color: black;
}
.title {
    padding: 10px;
    color: black;
    background: #a0cfec;
}
.info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 20px;
}
.price {
    text-align: center;
}
.price h1 {
    display: inline;
}
.discount {
    font-size: 0.8rem;
    color: #5ee45c;
}
.toggleContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}
.badge {
    position: absolute;
    background: rgba(97, 246, 114, 0.714);
    right: -55%;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    color: rgb(12, 48, 18);
    font-size: 0.7rem;
}
.description {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px;
}
.description li {
    list-style-type: '☑️';
    padding-inline-start: 1ch;
    margin-bottom: 5px;
}
.select button {
    display: block;
    width: 50%;
    margin: 20px auto;
}

@media screen and (max-width: 968px) {
    .title {
        font-size: 1.2rem;
    }
    .toggle {
        font-size: 0.8rem;
    }
    .info h1 {
        font-size: 2rem;
    }
    .price {
        font-size: 0.9rem;
    }
    .info p {
        font-size: 0.8rem;
    }
    .info h3 {
        font-size: 1.2rem;
    }
    .info li {
        font-size: 0.8rem;
    }
}
