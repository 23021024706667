.container {
    margin: 50px 30px;
    max-width: 1400px;
}
.page {
    position: relative;
}
.back {
    filter: invert(100%);
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
    opacity: 0.7;
    position: absolute;
    top: 30px;
    left: 30px;
    width: 30px;
    height: auto;
    color: white;
    background: transparent;
    display: none;
}
.back svg {
    height: 30px;
}

@media screen and (min-width: 1550px) {
    .back {
        top: 100px;
        left: 50px;
    }
    .container {
        margin: 70px 150px;
    }
}
