.container {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 100% auto;
    height: 90%;
    padding-top: 20px;
    width: 1400px;
}
.fullscreen {
    width: 60%;
}
.approval {
    width: 800px;
}
.preview {
    height: 100%;
}

.success {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}
.buttons {
    display: flex;
    gap: 20px;
}
.container textarea {
    height: 160px;
    width: 100%;
}
.endBtn {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.step {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.step::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.aside {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}
.actions {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
    width: 80%;
}
.actions button {
    margin: 0;
}
.nav {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* overflow-y: auto; */
}

.stepTitle {
    margin-bottom: 20px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.row > div {
    flex: 1;
}
.front {
    justify-content: flex-end;
}

.formField {
    display: flex;
    flex-direction: column;
}
.formFieldHeader {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 10px;
    align-items: center;
}
.formFieldHeader button {
    margin: 0;
}

@media (max-width: 2000px) {
    .container {
        width: 1200px;
    }
    .approval {
        width: 800px;
    }
    .fullscreen {
        width: 80%;
    }
}
@media (max-width: 1500px) {
    .container {
        width: 1000px;
    }
    .approval {
        width: 700px;
    }
    .fullscreen {
        width: 90%;
    }
}
@media (max-width: 1200px) {
    .approval {
        width: 600px;
    }
}
