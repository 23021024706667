.wrapper {
    position: relative;
}

.examples {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 400px;
    overflow: auto;
    background: var(--main-selectbox);
    border-radius: 4px;
    z-index: 10;
    margin-top: -20px;
}
.required::after {
    content: ' *';
    color: red;
}

.examples li {
    padding: 7px 15px;
    list-style-type: none;
}

.examples li:hover {
    background: var(--grey);
    cursor: pointer;
}

.wrapper label {
    font-size: 0.85rem;
}
