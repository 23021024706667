.wrapper {
    display: flex;
    flex-flow: column;
    gap: 15px;
}
.info {
    display: flex;
    flex-flow: column;
    /* gap: 5px; */
}
.status {
    min-width: 50px;
}
.model {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
}
.newBadge {
    position: absolute;
    transform: translateY(calc(-100% - 10px));
}
.versions {
    color: #51a6d9;
    display: flex;
    align-items: center;
    gap: 10px;
}
.select {
    background: transparent;
    color: white;
    border: 1px solid white;
    padding: 5px;
    border-radius: 5px;
}
.actions {
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
}
.actions button {
    margin: 0;
}
.buttonText {
    font-weight: 600;
}

@media (max-width: 920px) {
    .buttonText {
        display: none;
    }
    .model {
        width: 100%;
    }
    .info {
        gap: 5px;
    }
    .info h2 {
        font-size: 24px;
    }
    .info h3 {
        font-size: 18px;
    }
    .info h4 {
        font-size: 16px;
    }
    .versions select {
        max-width: 50%;
    }
    .versions {
        align-items: flex-start;
        flex-flow: column;
    }
    .versionDropdown {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
    }
}
