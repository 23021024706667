/* Results page */
.wrapper {
    position: relative;
    margin: var(--top-margin) auto calc(var(--bottom-margin) + 100px) auto;
    width: 95%;
    color: var(--main-white);
}
/* .wrapper:after {
    display: block;
    width: 100%;
    content: '';
    height: 100px;
    background: white;
} */
.approved svg {
    color: rgb(112, 197, 73);
}
.loading {
    width: 100%;
    height: 500px;
    color: #c5c5c5;
    flex-flow: column;
}
.loading,
.noresult {
    gap: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loupe {
    height: 60px;
}
.loupe svg {
    height: 100%;
}
.reportbtn {
    float: right;
}
.missing {
    filter: invert(100);
    height: 30px;
    margin: -5px 6px -10px -10px;
}
.section {
    display: flex;
    flex-direction: column;
    gap: 0px;
}
/* .filters {
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
} */
.results {
    /* top: 120px; */
    position: relative;
    /* flex-grow: 3; */
}
.results h2 {
    margin-bottom: 20px;
}
.list {
    width: fit-content;
    /* width: 100%; */
    border-collapse: collapse;
}
.list thead {
    text-align: left;
    border-bottom: 1px solid var(--bright-grey);
}
.list tr:nth-child(2n) {
    background: var(--bright-grey);
}
.list td,
.list th {
    padding: 15px;
}
.list td:last-child {
    padding-right: 0;
}
.list th {
    min-width: 150px;
    font-weight: normal;
    font-size: 14pt;
    cursor: pointer;
}
.list tr {
    cursor: pointer;
}

.edit-button {
    position: absolute;
    transform: translateX(-80px);
}

.suggestedText {
    width: 200%;
    font-weight: 500;
    /* position: absolute; */
    margin-top: 10px;
}
.suggestedText span {
    color: rgb(44, 131, 218);
}

/* Pagination */
.wrapper2 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

@media (min-width: 768px) {
    .wrapper2 {
        display: none;
    }
}
.box {
    border: 1px solid var(--extra-bright-grey);
    background: var(--extra-bright-grey);
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.preview {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.preview svg {
    padding-left: 15px;
    width: 25px;
    height: 25px;
}
.info {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 15px;
    padding-right: 20px;
}
.info a:hover {
    cursor: pointer;
}
.title {
    font-size: 0.8rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.description {
    font-size: 0.6rem;
    font-weight: 300;
}

.large {
    flex: 0 1 150px;
}
.large .box {
    gap: 20px;
    /* width: 150px;
    height: 150px; */
    flex-grow: 0;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.large .info {
    width: 100%;
    padding: 10px;
}
.large .preview svg {
    padding-top: 20px;
    padding-left: 0;
    width: 50px;
    height: 50px;
}

.extra {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 0.6rem;
    font-weight: 300;
    background: var(--extra-bright-grey);
    padding: 5px;
    border-radius: 15px;
    width: 100%;
}
