.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.notStarted {
    color: grey;
}

.inProgress {
    color: white;
}

.finished {
    color: green;
}
