.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}
.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    justify-content: center;
}
.info h3 {
    margin: 25px 0 15px 0;
}
.form {
    width: 80%;
}
.email,
.email input {
    width: 100%;
}
