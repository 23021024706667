.default {
    --outline-color: rgb(128, 128, 128);
    --header-bg: rgb(83, 83, 83);
    --cell-bg: rgb(37, 37, 37);
}
.info {
    --outline-color: rgb(22, 152, 231);
    --header-bg: rgb(41, 83, 109);
    --cell-bg: rgb(20, 42, 54);
}
.critical {
    --outline-color: rgb(231, 217, 22);
    --header-bg: rgb(109, 99, 41);
    --cell-bg: rgb(54, 51, 20);
}
.wrapper {
    width: 100%;
    display: flex;
    flex-flow: column;
    border: 1px solid var(--outline-color);
    background: var(--cell-bg);
    border-radius: 10px;
}
.headers {
    background: var(--header-bg);
    display: flex;
    border-radius: 9px 9px 0px 0px;
    border-bottom: 1px solid var(--outline-color);
}
.headerCell {
    padding: 10px 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
}
.headerCell + .headerCell,
.cell + .cell {
    border-left: 1px solid var(--outline-color);
}
.row {
    display: flex;
}
.row + .row {
    border-top: 1px solid var(--outline-color);
}
.cell {
    padding: 10px 15px;
    position: relative;
}
.cell ul {
    list-style: disc;
    padding-left: 10px;
}
.trancateTextWrapper {
    max-height: 4lh;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    transition: max-height 1.85s;
}
.trancateInput {
    bottom: 0;
    clip: rect(0);
    height: 1px;
    width: 1px;
    overflow: hidden;
    position: absolute;
    padding: 0;
}
.trancateInput:checked ~ .trancateTextWrapper {
    line-clamp: unset !important;
    -webkit-line-clamp: unset !important;
    max-height: 100lh;
}
.trancateInput:not(:checked) ~ .trancateLabel::after {
    content: 'Read more';
    color: var(--outline-color);
    text-decoration: underline;
}
.trancateInput:checked ~ .trancateLabel::after {
    content: 'Read less';
    color: var(--outline-color);
    text-decoration: underline;
}

@media (max-width: 768px) {
    /* .wrapper {
        overflow-x: auto;
    } */

    .headers,
    .row {
        min-width: 100%;
        display: flex;
    }

    .headerCell,
    .cell {
        padding: 8px 12px;
        font-size: 12px;
        text-align: start;
    }
}
