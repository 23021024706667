.container {
    padding: 40px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.search {
    margin-bottom: 30px;
    width: 300px;
}
