.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .box {
        position: relative;
        padding: 75px 60px;
    }
}
.bgBlur {
    background: rgba(0, 0, 0, 0.7);
}
.important {
    z-index: 1001;
}
.small .box {
    text-align: center;
    color: rgb(255, 255, 255);
    background: rgb(114, 114, 114);
    border-radius: 10px;
    border: 1px solid rgb(255, 255, 255);
}
.large .box,
.xl .box {
    height: 80vh;
    border-radius: 15px;
    background: var(--main-grey);
    box-shadow: #2a2a2a 2px 2px 20px 2px;
}
.xl .box {
    padding: 20px 30px;
    height: 95vh;
    /* overflow-y: auto; */
}
.xl .close {
    right: 10px;
    top: 10px;
}
.content {
    height: 100%;
}
.close {
    position: absolute;
    cursor: pointer;
    right: 30px;
    top: 30px;
}
