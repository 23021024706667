.container {
    padding: 30px;
    border-radius: 15px;
    border: 1px solid #2e2e2e;
    background: #181818;
    display: flex;
    flex-flow: column;
    gap: 20px;
    flex-grow: 1;
}
