.app {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 250px auto;
    color: var(--main-white);
    transition: transform 0.3s ease-in-out;
}

.page {
    background: var(--light-grey);
    height: 100%;
    overflow-y: auto;
    /* padding-top: 40px; */
    grid-column: 2 / 3;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.page::-webkit-scrollbar {
    display: none;
}

/* .app select {
    border: 1px solid grey;
} */

@media screen and (max-width: 768px) {
    .app {
        grid-template-columns: 0 auto;
    }

    .page {
        padding-top: 50px !important;
    }

    .app.open {
        transform: translateX(250px);
    }
}
