.implant {
    display: flex;
    gap: 20px;
    flex-flow: column;
}

.infoWrapper,
.detailsWrapper {
    display: flex;
    gap: 20px;
    flex-flow: row;
    justify-content: space-between;
}
.image,
.deptTools {
    width: 30%;
    min-width: 350px;
}
.detailsWrapper {
    flex-direction: row-reverse;
}
.details {
    flex-grow: 1;
}
.customField {
    margin: 30px 0;
}

.deptTools,
.deptActions {
    display: flex;
    flex-flow: column;
    gap: 20px;
}

.deptActions > button {
    width: 100%;
    justify-content: center;
    margin: 0;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 920px) {
    .implant {
        flex-flow: column;
    }
    .infoWrapper,
    .detailsWrapper {
        flex-flow: column;
    }
    .image,
    .deptTools {
        width: 100%;
        min-width: 0;
    }
}
