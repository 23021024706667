.wrapper {
    display: flex;
    flex-flow: column;
    gap: 30px;
    margin: 50px auto;
    width: 80%;
}
.wrapper h2 {
    margin-bottom: 15px;
}
.reapprove {
    margin: 0;
}
.preview {
    width: 40vw;
    margin: 50px;
}
.preview p {
    text-align: left;
}
